export default [
  {
    title: 'Anasayfa',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Girişimler',
    route: 'Startups',
    icon: 'TargetIcon',
    resource: 'APP_STARTUPS',
    action: 'read',
  },
  {
    title: 'Yatırımcılar',
    route: 'Investors',
    icon: 'StarIcon',
    resource: 'APP_INVESTORS',
    action: 'read',
  },
  {
    title: 'Benim Sayfam',
    route: '#',
    icon: 'EyeIcon',
    children: [
      {
        title: 'Favoriler',
        route: 'Favorites',
        icon: 'HeartIcon',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
      {
        title: 'Girişim Başvurularım',
        route: 'StartupApplies',
        icon: 'InfoIcon',
        resource: 'APP_REQUESTS',
        action: 'read',
      },
      {
        title: 'Portföyüm',
        route: 'Portfolio',
        icon: 'BriefcaseIcon',
        resource: 'APP_PORTFOLIO',
        action: 'read',
      },
      {
        title: 'Siparişlerim',
        route: 'AppOrders',
        icon: 'ShoppingCartIcon',
        resource: 'APP_ORDERS',
        action: 'read',
      },
      {
        title: 'Profil Bilgilerim',
        route: 'Profile',
        icon: 'UserIcon',
        resource: 'APP_PROFILE',
        action: 'read',
      },
      {
        title: 'Yatırımcı Profilim',
        route: 'InvestorProfile',
        icon: 'CoffeeIcon',
        resource: 'APP_INVESTOR_PROFILE',
        action: 'read',
      },
    ],
  },
  {
    title: 'SSS',
    route: 'FaqApp',
    icon: 'HelpCircleIcon',
    resource: 'APP_FAQ',
    action: 'read',
  },
  {
    header: 'Yönetim',
    resource: 'ADMIN',
    action: 'read',
  },
  {
    title: 'Siparişler',
    route: '#',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Tümü',
        route: 'Orders',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
      {
        title: 'Bekleyen',
        route: 'WaitingOrders',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
      {
        title: 'Tamamlanan',
        route: 'CompletedOrders',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
      {
        title: 'İptal',
        route: 'CanceledOrders',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Girişim Başvuruları',
    route: 'StartupAppliesAdmin',
    icon: 'BriefcaseIcon',
    resource: 'ADMIN_STARTUP_APPLIES',
    action: 'read',
  },
  {
    title: 'Yatırımcılar',
    route: 'InvestorsAdmin',
    icon: 'ShieldIcon',
    resource: 'ADMIN_INVESTORS',
    action: 'read',
  },
  {
    title: 'Paketler',
    route: 'Packages',
    icon: 'PackageIcon',
  },
  {
    title: 'Ayarlar',
    route: '#',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'SMTP',
        route: 'Smtp',
        resource: 'ADMIN_SMTP',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kullanıcılar',
    route: '#',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'ADMIN_USERS',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'UserTypes',
        resource: 'ADMIN_USER_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'SSS',
    route: '#',
    icon: 'HelpCircleIcon',
    children: [
      {
        title: 'Sorular',
        route: 'Faqs',
        resource: 'ADMIN_FAQ',
        action: 'read',
      },
      {
        title: 'Kategoriler',
        route: 'FaqCategories',
        resource: 'ADMIN_FAQ_CATEGORIES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Sabit İçerikler',
    route: 'Terms',
    icon: 'FileIcon',
    resource: 'ADMIN_TERMS',
    action: 'read',
  },
  {
    title: 'Tanımlamalar',
    route: '#',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Faaliyet Alanları',
        route: 'ActivityAreas',
        resource: 'ADMIN_ACTIVITY_AREAS',
        action: 'read',
      },
      {
        title: 'Pazarlama Odağı',
        route: 'MarketingFocus',
        resource: 'ADMIN_MARKETING_FOCUS',
        action: 'read',
      },
      {
        title: 'Hüküm ve Koşullar',
        route: 'StartupConfigs',
        resource: 'ADMIN_STARTUP_CONFIGS',
        action: 'read',
      },
      {
        title: 'Girişimci Durumları',
        route: 'StartupStatuses',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Banka Hesap Bilgileri',
        route: 'BankAccounts',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
    ],
  },
]
